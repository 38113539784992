/**
 * constants to be used for location specific logic
 */

export const AGE_MINIMUM = 21

export const SAN_JOSE = {
  GUILD: 46,
  CALIVA: 344
}

export const CITY_CONSTANTS = {
  APTOS: 'Aptos',
  CAPITOLA: 'Capitola',
  DEFAULT: 'default',
  MARINA_DEL_REY: 'Marina del Rey',
  SANTA_CRUZ: 'Santa Cruz',
  SOQUEL: 'Soquel',
  TWIN_LAKES: 'Twin Lakes'
}

/**
 * Messages to be used in modals
 * the best way to do this would be to have an endpoint for creating/consuming custom
 * location based messaging but we don't have the resources/time to do this in the backend
 */

export const cityData = {
  [CITY_CONSTANTS.DEFAULT]: {
    title: 'Sad news...',
    body: "We aren't in your area yet, but we're always adding new areas. Check out our sample menu in the meantime."
  }
}

export default {
  cityData,
  AGE_MINIMUM,
  SAN_JOSE,
  CITY_CONSTANTS
}

import schedule from '@eaze/schedule'
import isEmptyObject from 'is-empty-object'
import { DeepPartial } from 'redux'
import { createSelector } from 'reselect'

import { DeliveryWindow } from '@bff/models/og/places'
import { parseDateLong } from '@helpers/date'

import { getCartSubtotal } from '../cart/selectors'
import { CART_MINIMUM } from '../helpers/cart'
import { cityData, CITY_CONSTANTS } from '../helpers/location'
import pullOutLocations from '../helpers/pull-out-locations'
import { RootState } from '../reducers'

export const getLocation = (state: RootState) => state.location

export const getActiveLocation = createSelector([getLocation], (location) => location.activeLocation)

export const getPotentialAddress = createSelector([getLocation], (location) => location.potentialAddress)

// should only happen when moving from landing page into menu or if for some reason
// the location key in localStorage is deleted
export const getMissingActiveLocation = createSelector([getActiveLocation], (activeLocation) =>
  isEmptyObject(activeLocation)
)

export const isAdultUseAllowed = createSelector(
  [getLocation, getMissingActiveLocation],
  (location, isMissingActiveLocation) => {
    // when the user first logs in we have no location data
    // and adultUseCityAndDepot is by default set to false,
    // return true here if missing activelocation (still loading) so we don't blip any medical only banners/modals
    if (isMissingActiveLocation) return true
    // This property is set when we receive location data, and is a combination of the locale's adultUse boolean, and it's contained depot's adultUse boolean.
    return location.adultUseCityAndDepot
  }
)

export const getActiveDepot = createSelector([getActiveLocation], (activeLocation) => activeLocation.depot || {})

// Uses city or zip as a key in order to set custom messages for specific out of location cities
// There are 2 sources of out-of-servicing messages - helpers/location's cityData and helpers/pull-out-locations
// use cityData for customized location-specific messaging, use pull-out-locations for using a fixed message about pulling out because of regulations
export const getOutOfServiceAreaMessaging = createSelector([getActiveLocation], (activeLocation) => {
  const { city, zip } = activeLocation

  const pullOutLocation = pullOutLocations[zip] || pullOutLocations[city]
  const currentCityData = pullOutLocation
    ? {
        title: "We'll be back!",
        body: `Due to state licensing delays, Eaze is temporarily unavailable in ${pullOutLocation}. We expect to be back in a few days!`
      }
    : cityData[city] || cityData[CITY_CONSTANTS.DEFAULT]

  return currentCityData
})

export const getActiveDepotId = createSelector([getActiveDepot], ({ id }) => id)

export const isPulloutArea = createSelector([getActiveLocation], (activeLocation) => {
  const { city, zip } = activeLocation

  return Boolean(pullOutLocations[zip] || pullOutLocations[city])
})

export const getNoAvailableDepot = createSelector([getActiveDepot], (activeDepot) => isEmptyObject(activeDepot))

export const getActiveDepotSchedule = createSelector([getActiveDepot], (depot) => depot.schedule || [])

// existence of open time message means the store is currently closed
// returns null if currently open
export const getOpenTime = createSelector([getActiveDepotSchedule], (depotSchedule) => {
  if (!depotSchedule || depotSchedule.length === 0) return null
  return schedule.next(depotSchedule, new Date())
})

export const getCloseTime = createSelector([getActiveDepotSchedule], (depotSchedule) => {
  if (!depotSchedule || depotSchedule.length === 0) return null
  return schedule.nextClose(depotSchedule, new Date())
})

export const getStoreClosed = createSelector([getOpenTime], (openTime) => openTime)

export const getInSampleLocation = createSelector([getActiveLocation], (location) => isEmptyObject(location))

export const getDepotMinimumOrder = createSelector([getActiveDepot], (depot) => {
  return depot.minimumOrderAmount || CART_MINIMUM
})

export const getActiveDepotDeliveryType = createSelector([getActiveDepot], (depot) => depot.deliveryType)

export const getAllDayDepot = createSelector([getActiveDepot], (depot) => depot.isDayAndNight)

export const getIsExactAddress = (state: RootState) =>
  state.location.activeLocation && state.location.activeLocation.exact

export const isResidentialOnly = createSelector([getLocation], (location) => location.residentialOnly)

export const isIdScanRequired = createSelector([getLocation], (location) => location.idScanRequired)

export const isUsaIdOnly = createSelector([getLocation], (location) => location.isUsaIdOnly)

export const getActiveDepotPaymentSettings = createSelector([getActiveDepot], (depot) => {
  return depot.depotPaymentSettings || {}
})

export const getMinimumAmountBypassDeliveryFee = createSelector(
  [getActiveDepotPaymentSettings],
  (depotPaymentSettings) => {
    return depotPaymentSettings.minimumAmountBypassDeliveryFee || CART_MINIMUM
  }
)

export const getRemainingAmountBypassDeliveryFee = createSelector(
  [getCartSubtotal, getMinimumAmountBypassDeliveryFee],
  (cartSubtotal, minimumAmountBypassDeliveryFee) => {
    return minimumAmountBypassDeliveryFee - cartSubtotal
  }
)

export const getHasScheduledDelivery = createSelector([getActiveDepot], (depot) => depot.scheduledDeliveryEnabled)

export const getAvailableDeliveryWindows = createSelector([getActiveDepot], (depot) => depot.deliveryWindows || [])

export const getDepotTimeZone = createSelector([getActiveDepot], (depot) => depot.timeZone)

export const availableDeliveryWindowsByDay = createSelector(
  [getAvailableDeliveryWindows],
  (availableDeliveryWindows) => {
    const windowsByDay: { [key: string]: DeepPartial<DeliveryWindow>[] } = {}

    availableDeliveryWindows
      .sort((a, b) => new Date(a.timeFrom).getTime() - new Date(b.timeFrom).getTime())
      .forEach((period) => {
        const periodDate = parseDateLong(period.timeFrom)

        if (windowsByDay[periodDate]) {
          windowsByDay[periodDate].push(period)
        } else {
          windowsByDay[periodDate] = [period]
        }
      })

    return windowsByDay
  }
)

export default {
  getLocation,
  getActiveLocation,
  getPotentialAddress,
  getMissingActiveLocation,
  isAdultUseAllowed,
  getActiveDepot,
  getOutOfServiceAreaMessaging,
  isPulloutArea,
  getNoAvailableDepot,
  getActiveDepotSchedule,
  getOpenTime,
  getCloseTime,
  getStoreClosed,
  getInSampleLocation,
  getDepotMinimumOrder,
  getIsExactAddress,
  getActiveDepotDeliveryType,
  getActiveDepotPaymentSettings,
  getMinimumAmountBypassDeliveryFee,
  getRemainingAmountBypassDeliveryFee,
  getAllDayDepot,
  isResidentialOnly,
  isIdScanRequired,
  isUsaIdOnly
}
